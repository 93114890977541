<template>
  <div>
    <Divider dashed><span class="divider-text">{{ workTaskInfo.stationName }} 作业令编辑</span></Divider>
    <div class="m-t-10">
      <h4 class="workplatform-title p-b-5">作业日期</h4>
      <Tag color="primary">{{ actionDateModel.workday }}</Tag>
    </div>
    <div class="m-t-10">
      <h4 class="workplatform-title p-b-5"><span class="text-red p-r-5">*</span>作业起止时间</h4>
      <Row :gutter="8">
        <i-col span="12">
          <div>开始时间</div>
          <div>
            <!-- <TimePicker
              transfer
              :clearable="false"
              format="HH:mm"
              size="small"
              placement="bottom-end"
              placeholder="选择开始时间"
              style="width: 100%"
              v-model="startTime"
            ></TimePicker> -->
            <DatePicker transfer :clearable="false" :options="sTimeOptions" type="datetime" format="yyyy-MM-dd HH:mm"
              size="small" placeholder="选择开始时间" style="width: 100%" v-model="startTime"></DatePicker>
          </div>
        </i-col>
        <i-col span="12">
          <div>结束时间</div>
          <div>
            <!-- <TimePicker
              transfer
              :clearable="false"
              format="HH:mm"
              size="small"
              placement="bottom-end"
              placeholder="选择结束时间"
              style="width: 100%"
              v-model="endTime"
            ></TimePicker> -->
            <DatePicker transfer :clearable="false" :options="eTimeOptions" type="datetime" format="yyyy-MM-dd HH:mm"
              size="small" placeholder="选择结束时间" style="width: 100%" v-model="endTime"></DatePicker>
          </div>
        </i-col>
      </Row>
    </div>
    <div class="m-t-10">
      <h4 class="workplatform-title p-b-5"><span class="text-red p-r-5">*</span>施工负责人</h4>
      <Select v-model="actionDateModel.executeUserId" palceholder="请选择施工负责人" size="small" :clearable="false"
        @on-change="handleChangeWorker">
        <Option v-for="item in workerArray" :key="'userId_' + item.userId" :value="item.userId">{{ item.name }}</Option>
      </Select>
    </div>
    <div class="m-t-10">
      <h4 class="workplatform-title p-b-5">备注</h4>
      <div>
        <Input v-model="actionDateModel.remark" type="textarea" placeholder="请填写备注" />
      </div>
    </div>
    <div class="m-t-10">
      <h4 class="p-b-5">附件上传</h4>
      <Upload multiple :before-upload="handleUpload"
        accept=".png,.jpg,.jpeg,.doc,.docx,.xls,.xlsx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        :data="actionDateModel" type="drag" action>
        <div style="padding: 5px 0">
          <Icon type="ios-cloud-upload" size="40" style="color: #3399ff"></Icon>
          <p>选择上传文件</p>
        </div>
      </Upload>
      <p class="text-orange">提示：</p>
      <p>仅只支持JPG，PNG，Excel，Word，PDF。</p>
      <Row v-show="fileList.length || selectedTaskitemFiles.length">
        <i-col span="18">
          <b>文件名称</b>
        </i-col>
        <i-col span="6" class="text-center">
          <b>操作</b>
        </i-col>
      </Row>
      <hr v-show="fileList.length" />
      <Row v-for="(file, fileIndex) in fileList" :key="fileIndex" v-show="fileList.length">
        <i-col span="18">{{ file.name.length > 20 ? file.name.substring(0, 19) + '...' : file.name }}</i-col>
        <i-col span="6" class="text-center">
          <a class="delete" @click="handleRemove(file)">删除</a>
        </i-col>
        <i-col span="24">
          <Divider dashed style="margin:5px 0" />
        </i-col>
      </Row>
      <Row v-for="(file, index) in selectedTaskitemFiles" :key="'exitsFile_' + index"
        v-show="selectedTaskitemFiles.length">
        <i-col span="18">
          <Tooltip :content="file.fileName">{{ file.fileName.length > 20 ? file.fileName.substring(0, 19) + '...' :
            file.fileName }}
          </Tooltip>
        </i-col>
        <i-col span="6" class="text-center">
          <a class="m-r-5" @click="handleDownloadFiles(file)">下载</a>
          <a class="delete" @click="handleRemoveExitsFiles(file)">删除</a>
        </i-col>
        <i-col span="24">
          <Divider dashed style="margin:5px 0" />
        </i-col>
      </Row>
    </div>
    <div class="m-t-10 text-right">
      <Button type="success" size="small" :loading="btnSubmitLoading" @click="handleSubmit">确认编辑</Button>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { sysMixins } from '@/assets/mixins/sys'
import { getStorage } from '@/utils/storage'
import { ParseDate, ParseDateTime } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'
export default {
  mixins: [sysMixins],
  data () {
    return {
      actionDateModel: {
        workday: '',
        startTime: '',
        endTime: '',
        dayType: 1,
        executeCompanyId: null,
        executeUserId: null,
        remark: '',
        files: null
      },
      startTime: '',
      endTime: '',
      sTimeOptions: {},
      eTimeOptions: {
      },
      chooseAssetId: null,
      checkedStations: [],
      // workerArray: [],
      fileList: [],
      selectedTaskitemFiles: [],
      btnSubmitLoading: false
    }
  },
  computed: {
    workerArray () {
      return this.$store.state.actionDate.workerArray
    },
    workTaskInfo () {
      return this.$store.state.actionDate.workTaskInfo
    }
  },
  methods: {
    handleChangeWorker () {
      this.actionDateModel.executeCompanyId = this.actionDateModel.executeUserId ? this.workerArray.find(x => x.userId === this.actionDateModel.executeUserId).companyId : null
    },
    handleUpload (file) {
      if (!this.fileList.some(x => x.name === file.name)) {
        this.fileList.push(file)
      }
      return false
    },
    handleRemove (file) {
      this.fileList.splice(this.fileList.indexOf(file), 1)
    },
    handleRemoveExitsFiles (file) {
      this.selectedTaskitemFiles.splice(this.selectedTaskitemFiles.indexOf(file), 1)
    },
    handleDownloadFiles (file) {
      downloadFileRequest(file.fullFileKey, null, file.fileName)
    },
    handleSubmit () {
      if (!this.actionDateModel.executeUserId) {
        this.$Notice.warning({ desc: '请选择施工负责人' })
        return false
      }

      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要编辑当前站点作业令信息？',
        onOk: () => {
          this.btnSubmitLoading = true
          // const sTime = new Date(`${ParseDate(this.actionDateModel.workday)} ${this.startTime}`)
          // const eTime = new Date(`${ParseDate(this.actionDateModel.workday)} ${this.endTime}`)

          this.actionDateModel.startTime = ParseDateTime(this.startTime)
          this.actionDateModel.endTime = ParseDateTime(this.endTime)

          // if (sTime.valueOf() > eTime.valueOf()) {
          //   // 时间段跨天处理，开始时间大于结束时间，则结束日期加一天
          //   const tempEndTime = new Date(eTime.setDate(eTime.getDate() + 1))
          //   this.actionDateModel.endTime = ParseDateTime(tempEndTime)
          //   this.actionDateModel.startTime = ParseDateTime(sTime)
          // } else {
          //   // 时间段非跨天处理
          //   const limitDate = new Date(`${ParseDate(this.actionDateModel.workday)} 22:00:00`)
          //   if (sTime.valueOf() < limitDate.valueOf() && eTime.valueOf() < limitDate.valueOf()) {
          //     const tempStime = new Date(sTime.setDate(sTime.getDate() + 1))
          //     const tempEtime = new Date(eTime.setDate(eTime.getDate() + 1))

          //     this.actionDateModel.startTime = ParseDateTime(tempStime)
          //     this.actionDateModel.endTime = ParseDateTime(tempEtime)
          //   }
          // }

          const postData = new FormData()
          postData.append('assetId', this.chooseAssetId)
          postData.append('dayType', this.actionDateModel.dayType)
          postData.append('startTime', this.actionDateModel.startTime)
          postData.append('endTime', this.actionDateModel.endTime)
          postData.append('executeUserId', this.actionDateModel.executeUserId)
          postData.append('executeCompanyId', this.actionDateModel.executeCompanyId)
          postData.append('workday', ParseDate(this.actionDateModel.workday))
          postData.append('stationIds', JSON.stringify(this.checkedStations))
          postData.append('remark', this.actionDateModel.remark)
          postData.append('exitFileKeys', JSON.stringify(this.selectedTaskitemFiles.map(x => x.fileKey)))
          postData.append('workdayId', this.workTaskInfo.id)
          if (this.fileList && this.fileList.length) {
            this.fileList.forEach((file) => {
              postData.append('files', file)
            })
          } else {
            postData.append('files', null)
          }
          axios.post(
            process.env.VUE_APP_API_URL_V2 +
            '/ooh-msp/v2/workday/addworkday',
            postData,
            {
              timeout: 15000, // 请求超时
              headers: {
                'Content-Type': 'multipart/form-data',
                'ooh-auth': getStorage('ooh-token')
              },
              withCredentials: true
            }
          ).then(res => {
            this.btnSubmitLoading = false
            if (res.data && res.data.errcode === 0) {
              this.actionType = 1
              this.actionDateModel.workday = ''
              this.startTime = ''
              this.endTime = ''
              this.actionDateModel.dayType = 1
              this.actionDateModel.executeUserId = null
              this.actionDateModel.executeCompanyId = null
              this.checkedStations = []
              this.actionDateModel.remark = ''
              this.selectedTaskitemFiles = []
              this.fileList = []
              this.$Notice.success({ desc: '操作成功' })
              this.$store.dispatch('getActionDateDetailData')

              // 隐藏左侧编辑区域
              this.setShowLeftBlock(false)
              this.setLeftComponent('')
            }
          })
        }
      })
    }
  },
  watch: {
    workTaskInfo: {
      deep: true,
      immediate: true,
      handler (val) {
        if (Object.keys(val).length) {
          this.actionType = [1, 2].includes(val.dayType) ? 1 : 2
          this.actionDateModel.workday = val.day
          this.startTime = val.startTime
          this.endTime = val.endTime
          this.actionDateModel.dayType = val.dayType
          this.actionDateModel.executeUserId = val.executeUserId
          this.actionDateModel.executeCompanyId = val.executeCompanyId
          this.chooseAssetId = val.assetId
          this.checkedStations = [val.stationId]
          this.actionDateModel.remark = val.remark
          this.selectedTaskitemFiles = val.fileKeyList
          this.sTimeOptions = {
            disabledDate (date) {
              return date && date.valueOf() < new Date(val.day).valueOf()
            }
          }
          this.eTimeOptions = {
            disabledDate (date) {
              return date && date.valueOf() < new Date(val.day).valueOf()
            }
          }
        } else {

        }
      }
    }
  }
}
</script>
